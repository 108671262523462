<template>
    <div class="mt-2 center insu-container">
        <span class="insu-title">{{title}}</span>
        <br>
        <span class="fs-20 price-landing">{{price}}</span>
        <span class="fs-16 price-landing"> {{currency}}  </span>
        <span class="fs-16"> / </span>
        <template v-if="periodicity == 1">
            <span  class="fs-20">{{$t("contractingLanding.twelve_month")}}</span>
            <br>
            <span class="fs-20">({{anualPrice}} $ {{$t("contractingLanding.year")}})</span>
        </template>
        <template v-else>
            <span class="fs-20">{{$t("contractingLanding.year")}}</span>
        </template>
    </div>
</template>

<script>

export default {
    name: 'InsurancePrice',
    props: {
        title: String,
        price: Number,
        anualPrice: Number,
        currency: String,
        periodicity: String
    }
}

</script>
<style lang="scss" scoped>

    .insu-container{
        margin-bottom: 15px;
    }

    .center{
        text-align: center;
    }
    .insu-title{
        font-size: 18px; 
    }

    .fs-20{
        font-size: 20px; 
    }

    .fs-16{
        font-size: 16px; 
    }
    .price-landing {
        font-weight: bold;
        color: black !important;
    }
</style>