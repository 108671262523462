import landing from '@/config/constants/landing.js'
import { getProductCategoryId } from '@/api/ApiClientUtils.js'
import dayjs from 'dayjs';
import { uuid } from 'vue-uuid';
import contactInfo from '@/config/constants/contactInfo.js'

export function getPolicyPriceParams(currentLanding, policyData) {
    var productID = ""
    var product_category_id = ""
    var periodicity = policyData.periodicity
    if (currentLanding != landing.seguromultidispositivo.name) {
        productID = policyData.product.product_id
        product_category_id = getProductCategoryId(policyData.purchasePrice, policyData.product)
    } else {
        productID = policyData.product_id
        product_category_id = getProductCategoryId(policyData.capital.amount, policyData.product)
    }

    var params = {
        "product_id": productID,
        "periodicity": periodicity,
        "discount_code": policyData.discountCode
    }

    if (currentLanding != landing.seguropatinete.name) {
        params["product_category_id"] = product_category_id
    } else {
        params["purchase_price"] = policyData.purchasePrice
    }

    return params
}

export function getGenericItemParams(currentLanding, id, userData, type) {
    var params = {
        "identifier": id + '_' + dayjs().format('YYYY-MM-DD HH:mm:ss'),
        "type": type,
        "value": {
            // "lead_type": "LOSTCART",
            "lead_product": currentLanding,
            // "lead_utm_source": currentLanding + " - LOSTCART",
            "lead_name": userData.name === "" || typeof userData.name === "undefined" ? "" : userData.name,
            "lead_firstname": userData.firstname === "" || typeof userData.firstname === "undefined" ? "" : userData.firstname,
            "lead_phone": userData.phone === "" || typeof userData.phone === "undefined" ? "" : userData.phone
        },
        "date": dayjs().add(5, 'minute').format('YYYY-MM-DD HH:mm:ss')
    }
    return params
}

function isMultidevice(currentLanding) {
    return currentLanding == landing.seguromultidispositivo.name || currentLanding == landing.seguromultidispositivovoltaje.name;
}

function isMobileTheft(currentLanding) {
    return currentLanding == landing.seguromovilrobo.name;
}

export function getSubmitPolicyParams(currentLanding, policyData, userData) {
    var productID = "";
    if (!isMultidevice(currentLanding)) {
        productID = policyData.product.product_id
    } else {
        productID = policyData.product_id
    }

    var params = {
        store_id: policyData.store_id? policyData.store_id: 1,
        product_category_id: policyData.idCategory,
        periodicity: 12,
        product_id: productID
    }

    //TODO: Cambio temporal
    if(policyData.periodicity == 1){
        params.finance = 1
        params.payment_method = 'tpv_12_finance'
    } else{
        params.finance = 0
        params.payment_method = 'tpv_12'
    }
    
    if(policyData.payment_method){
        params.payment_method = policyData.payment_method;
    }

    params.discount_code = policyData.discountCode

    //User Info
    params.name = userData.name
    params.email = userData.email
    params.phone_number = policyData.phone.replace(/\s/g, '');
    params.id_number = userData.nif
    params.acceptance_terms = userData.acceptance_type

    if (userData.acceptance_type_2 != 0) {
        params.acceptance_charges = userData.acceptance_type_2
    }
    if (userData.acceptance_coverages != 0) {
        params.acceptance_coverages = true

    }
    params.payment_type = "form"
    params.internal_observations = "web"

    // Device info
    if (policyData.reference != "") {
        params.reference = policyData.reference
    }
    if (policyData.device_type != "") {
        params.device_type = policyData.device_type
    }
    if (policyData.model != "") {
        params.model = policyData.model
    }
    if (policyData.purchaseDate && policyData.purchaseDate != "") {
        params.purchase_date = dayjs(policyData.purchaseDate).format("DD/MM/YYYY");
    }
    if (policyData.device && policyData.device != "") {
        params.model = `${policyData.device.brand} ${policyData.device.name}`
    }
    if(policyData.ticket_number){
        params.ticket_number = policyData.ticket_number;
    }
    if(userData.regimen_fiscal_code){
        params.regimen_fiscal_code = userData.regimen_fiscal_code;
    }

    /**Address fields */
    if (userData.postal_code != "") {
        params.zip = userData.postal_code
    }

    if (userData.birth_date != "") {
        params.birth_date = userData.birth_date
    }

    if (userData.state != "") {
        params.state = userData.state
    }

    if (userData.town != "") {
        params.town = userData.town
    }

    if (userData.colony != "") {
        params.colony = userData.colony
    }

    if (userData.street != "") {
        params.street = userData.street
    }

    if (userData.exterior_number != "") {
        params.exterior_number = userData.exterior_number
    }

    if (userData.inner_number != "") {
        params.inner_number = userData.inner_number
    }

    if(isMobileTheft(currentLanding)){
        params.imei = policyData.imei;
    }

    if (!isMultidevice(currentLanding)) {
        params.purchase_price = policyData.purchasePrice
    } else {
        var risksDevices = []
        policyData.risks.forEach(risk => {
            var riskDevice = {
                "purchase_price": risk.purchase_price,
                "model": risk.device.name,
                "brand": risk.device.brand
            }

            if (riskDevice.purchase_price != "") {
                risksDevices.push(riskDevice)
            }
        })
        params["risks"] = risksDevices
    }

    // Payment info

    /* En MX se introducen estos datos en Conekta
    params.card_holder = userData.name
    params.pan = userData.card_number
    params.cvv2 = userData.card_cvv
    params.expiration = '20' + userData.card_expiration_year + userData.card_expiration_month.padStart(2, '0')*/
        //En italia no hay pagos con adeudo
        /*if (userData.iban != "") {
            params.iban = userData.iban
        }*/

    // TODO: force cash payment
    params.card_holder = null
    params.pan = null
    params.cvv2 = null
    params.expiration = null
    params.payment_type = "direct"

    params.GA_client_id = document.cookie.match('(^|;)\\s*_ga\\s*=\\s*([^;]+)') ?.pop() || 'GA';
    params.GA_user_id = uuid.v5(params.phone_number, '65f9af5d-f23f-4065-ac85-da725569fdca')
    params.GA_email = uuid.v5(params.email, '65f9af5d-f23f-4065-ac85-da725569fdca')

    //TODO: Add prefix as a configurable param.
    if (!params.phone_number.trim().startsWith("+")) {
        params.phone_number = contactInfo.prefix + params.phone_number
    }

    params.url_ko = window.location.protocol + "//" + window.location.host + "/order-error" + window.location.search +
        '&phone=' + params.phone_number + '&name=' + userData.name;
    params.url_ok = window.location.protocol + "//" + window.location.host + "/order-ok" + window.location.search +
        '&phone=' + params.phone_number + '&name=' + userData.name;

    // TODO: Por ahora se manda a null, pero hay que averiguar que va en ese campo de campaña
    params.campaign_active=null
    return params
}

export function getContactmParams(contactData) {
    var params = {
        "name": contactData.name,
        "asunto": contactData.subject,
        "email": contactData.email,
        "nif": "",
        "phone_number": contactData.phoneNumber,
        "menssage": contactData.comments
    }

    return params
}